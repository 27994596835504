import React, { useMemo } from 'react';

import Head from 'next/head';

import { normalizeUrlPath } from ':helpers/string-helpers';
import { replaceUnicodes } from ':lib/_i18n';

import { LOCALES } from '../locales';

const fallbackImage =
  'https://rippling.imgix.net/images/PreviewImage_Product.png?fm=png';

const DEPLOYMENT_URI =
  process.env.NEXT_PUBLIC_DOMAIN_ORIGIN || 'https://www.rippling.com';

export interface HeadComponentProps {
  head: {
    mode?: {
      isDevelopment?: boolean,
      locale?: string,
      pageLocale?: string,
      pageLocaleDefaulted?: string
    };
    seo?: {
      _canonicalUrl?: string,
      _isIndexed?: boolean,
      description?: string,
      image?: string,
      title?: string,
      uri?: string
    }
    styles?: {
      criticalCSS?: string,
      missingAtomicCSS?: string
    }
  }
  alternativePages?: {
    locale: string;
    path: string;
  }[];
}

const getLocaleRoutePrefix = (locale) => {
  return locale === LOCALES.EN_US ? '' : `/${locale}`;
};

const getIsI18nNotAvail = ({ pageLocale, pageLocaleDefaulted }: HeadComponentProps['head']['mode']) => {
  if (!pageLocale) {
    return false;
  }

  return pageLocaleDefaulted;
};

const getDefaultAlternativePage = ({ pages }) => {
  if (pages.length === 0) {
    return null;
  }

  // US as fallback
  const defaultPage = pages.find(({ locale }) => locale === LOCALES.EN_US);

  if (defaultPage) {
    return defaultPage;
  }

  // Whatever is the first one if US is not available
  return pages[0] ?? null;
};

const HeadComponent = ({
  alternativePages: allAlternativePages = [],
  head,
}: HeadComponentProps) => {
  const {
    mode = {}, seo = {}, styles = {},
  } = head;
  const { criticalCSS, missingAtomicCSS } = styles;
  const {
    _canonicalUrl, _isIndexed, description, image, title, uri,
  } = seo;
  const { isDevelopment, locale: pageLocale = LOCALES.EN_US } = mode;

  const seoImage = image || fallbackImage;

  const shouldIndex = _isIndexed && !isDevelopment;
  const isI18nNotAvail = getIsI18nNotAvail(mode);
  const robotsIndexContent =
    shouldIndex && !isI18nNotAvail ? 'index, follow' : 'noindex, nofollow';
  const botsIndexContent = `${robotsIndexContent}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`;

  const sanitizedCanonical = normalizeUrlPath(_canonicalUrl);
  const localePrefix = getLocaleRoutePrefix(pageLocale);
  const currentPath = sanitizedCanonical
    ? `${localePrefix}${sanitizedCanonical}`
    : `${localePrefix}${uri}`;
  const currentUrl = currentPath ? `${DEPLOYMENT_URI}${currentPath}` : '';

  const { alternativePages, defaultAlternativePage } = useMemo(() => {
    const isPagePublishedToLocale = allAlternativePages.some(({ locale }) => {
      return pageLocale === locale;
    });

    if (isPagePublishedToLocale) {
      const defaultPage = getDefaultAlternativePage({ pages: allAlternativePages });

      // The page is available in the locale, returns the alternative pages
      return {
        alternativePages: allAlternativePages,
        defaultAlternativePage: defaultPage,
      };
    }

    if (!currentPath) {
      // Shouldn't happen
      return {
        alternativePages: [],
        defaultAlternativePage: null,
      };
    }

    return {
      alternativePages: [
        {
          locale: pageLocale,
          path: currentPath,
        },
      ],
      defaultAlternativePage: {
        locale: pageLocale,
        path: currentPath,
      },
    };
  }, [allAlternativePages, currentPath]);

  return (
    <Head>
      {currentUrl && <link href={currentUrl} rel="canonical" />}
      <title>{replaceUnicodes(title)}</title>
      <meta content={description} name="description" />
      {/* indexing */}
      <meta content={robotsIndexContent} name="robots" />
      <meta content={botsIndexContent} name="googlebot" />
      <meta content={botsIndexContent} name="bingbot" />
      {/* i18n */}
      {alternativePages.map(({ locale, path }) => {
        return (
          <link
            key={`link-locales-${locale}`}
            href={`${DEPLOYMENT_URI}${path}`}
            hrefLang={locale.toLowerCase()}
            rel="alternate"
          />
        );
      })}
      {defaultAlternativePage && (
        <link
          href={`${DEPLOYMENT_URI}${defaultAlternativePage.path}`}
          hrefLang="x-default"
          rel="alternate"
        />
      )}
      {/* og data (i18n) */}
      <meta

        // these should be lang_REGION (lowercase + _underscore_ + UPPER)
        content={pageLocale.replace(/-/, '_')}
        property="og:locale"
      />
      {/* og data common */}
      <meta content="website" property="og:type" />
      <meta content={title} property="og:title" />
      {description && <meta content={description} property="og:description" />}
      {currentUrl && <meta content={currentUrl} property="og:url" />}
      <meta content="Rippling" property="og:site_name" />
      <meta content={seoImage} property="og:image" />
      <meta content="940" property="og:image:width" />
      <meta content="490" property="og:image:height" />
      {/* Twitter */}
      <meta content="summary_large_image" name="twitter:card" />
      <meta content={seoImage} name="twitter:image" />,
      <meta content="@ripplingapp" name="twitter:creator" />
      <meta content="@ripplingapp" name="twitter:site" />
      {typeof window !== 'undefined' &&
      window.location.pathname === '/interactive-tour'
        && (
          <>
            <script src="https://js.storylane.io/js/v1/player_min.js" />,
            <script
              dangerouslySetInnerHTML={{
                __html: `
							function launchStorylaneDemo(){
								new StorylaneEmbed(
									{ dom_id: 'storylane-demo-section', // id of the dom element
										demo_url: 'https://app.storylane.io/demo/ynabww7wzqb1?embed=true'
									});
							}
						`,
              }}
            /></>
        )}
      {criticalCSS ? (
        <style
          dangerouslySetInnerHTML={{ __html: `${criticalCSS}` }}
        />
      ) :
        ''
      }
      {missingAtomicCSS ? (
        <style
          dangerouslySetInnerHTML={{ __html: `${missingAtomicCSS}` }}
        />
      ) :
        ''
      }
      {/* Used to fix "rogue referrer problem" https://www.simoahava.com/gtm-tips/fix-rogue-referral-problem-single-page-sites/ */}
      <script
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              originalLocation: document.location.protocol + '//' +
              document.location.hostname +
              document.location.pathname +
              document.location.search
            });`,
        }}
      />
      {/* <!-- Qualified (Start) --> */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){
            (w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified')`,
        }}
      />
      <script
        async
        src="https://js.qualified.com/qualified.js?token=xiZzNAGkA8hE9UwP"
      />
      {/* <!-- Qualified (End) --> */}
    </Head>
  );
};

export default HeadComponent;
