export const strings = {
  allArticlesText: {
    default: 'All Articles',
    'es-ES': 'Todos los artículos',
  },
  authorsText: {
    'de-DE': 'Der/Die Autor/innen',
    default: 'Authors',
    'es-ES': 'Los autores',
    'fr-FR': 'auteurs',
    'nl-NL': 'auteurs',
  },
  authorText: {
    'de-DE': 'Der/Die Autor/in',
    default: 'Author',
    'es-ES': 'El autor',
    'fr-FR': 'auteur',
    'nl-NL': 'auteur',
  },
  authorTexts: {
    'de-DE': 'Der/Die Author/in',
    default: 'The Author',
    'es-ES': 'El autor',
    'fr-FR': 'L’auteur',
    'nl-NL': 'De auteur',
  },
  exploreMoreText: {
    'de-DE': 'Mehr erkunden',
    default: 'Explore more',
    'es-ES': 'Explorar más',
    'fr-FR': 'En savoir plus',
    'nl-NL': 'Verken meer',
  },
  filterText: {
    'de-DE': 'Filtern nach',
    default: 'Filter by',
    'es-ES': 'Filtrar por',
    'fr-FR': 'Filtrer par',
    'nl-NL': 'Filteren op',
  },
  lastEditText: {
    'de-DE': 'Zuletzt bearbeitet',
    default: 'last edited',
    'es-ES': 'Última modificación',
    'fr-FR': 'Dernière modification',
    'nl-NL': 'Laatst bewerkt',
  },
  loadMoreText: {
    'de-DE': 'Mehr laden',
    default: 'Load more',
    'es-ES': 'Cargar más',
    'fr-FR': 'Charger plus',
    'nl-NL': 'Laad meer',
  },
  moreText: {
    'de-DE': 'Mehr+',
    default: 'More+',
    'es-ES': 'Más+',
    'fr-FR': 'Plus+',
    'nl-NL': 'Meer+',
  },
  noSearchResultsText: {
    default: 'Sorry we could not find anything matching',
    'es-ES': 'Lo siento, no pudimos encontrar nada que coincida',
  },
  popularSearchesListText: {
    default: [
      {
        href: '/blog/search?query=onboard%20remote%20employees',
        name: 'Onboard remote employees',
      },
      {
        href: '/blog/search?query=a%20better%20way%20to%20manage%20spending',
        name: 'A better way to manage spending',
      },
      {
        href: '/blog/search?query=automate%20your%20onboarding',
        name: 'Automate your onboarding',
      },
    ],
  },
  popularSearchesText: {
    default: 'Popular Searches',
    'es-ES': 'Búsquedas populares',
  },
  publishedText: {
    'de-DE': 'Veröffentlicht',
    default: 'Published',
    'es-ES': 'Publicado',
    'fr-FR': 'Publié',
    'nl-NL': 'Gepubliceerd',
  },
  readArticleText: {
    'de-DE': 'Artikel lesen',
    default: 'Read article',
    'es-ES': 'Leer artículo',
    'fr-FR': 'Voir plus',
    'nl-NL': ' Lees artikel',
  },
  readMoreText: {
    'de-DE': 'Mehr lesen',
    default: 'Read more',
    'es-ES': 'Leer más',
    'fr-FR': 'Voir plus',
    'nl-NL': 'Lees artikel',
  },
  reviewedByText: {
    'de-DE': 'Rezensiert von',
    default: 'Authors',
    'es-ES': 'Revisado por',
    'fr-FR': 'Corrigé par',
    'nl-NL': 'Beoordeeld door',
  },
  searchQueryPlaceholderText: {
    default: 'Search the Blog',
    'es-ES': 'Buscar en el blog',
  },
  titleText: {
    default: 'One place for Global HR, IT, & Finance',
    'es-ES': 'Un lugar para RRHH, TI y Finanzas globales',
  },
  tocText: {
    'de-DE': 'Inhaltsverzeichnis',
    default: 'Table of Contents',
    'es-ES': 'Tabla de contenidos',
    'fr-FR': 'Table des matières',
    'nl-NL': 'Inhoudsopgave',
  },
  topicsText: {
    'de-DE': 'Themen',
    default: 'Topics',
    'es-ES': 'Temas',
    'fr-FR': 'Sujets',
    'nl-NL': 'Onderwerpen',
  },
} as const;
