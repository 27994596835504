import Image from '../components/basic/Image';
import { checkIsDevelopment } from '../lib/_helpers';
import { setGlobalOptions } from '../lib/globals';

const FLAGS = {
  AU: 'https://rippling.imgix.net/images/AU-flag.svg',
  CA: 'https://rippling.imgix.net/images/CA-flag.svg',
  DE: 'https://rippling.imgix.net/images/germany.svg',
  ES: 'https://rippling.imgix.net/images/kEp43M7o-spain.svg',
  FR: 'https://rippling.imgix.net/images/FR-flag-1.svg',
  GB: 'https://rippling.imgix.net/images/GB-flag.svg',
  IE: 'https://rippling.imgix.net/images/IE-flag-1.svg',
  IN: 'https://rippling.imgix.net/images/india-flag.svg',
  IT: 'https://rippling.imgix.net/images/italy.svg',
  NL: 'https://rippling.imgix.net/images/netherlands.svg',
  PT: 'https://rippling.imgix.net/images/portugal.svg',
  US: 'https://rippling.imgix.net/images/US-4.svg',
};

export const getFlag = (country, additionalClasses) => (
  <Image
    attributes={{
      className: `w-24 h-24 rounded-50% object-cover ${
        additionalClasses || ''
      }`,
      mediaURL: FLAGS[country],
      nonResponsive: true,
    }}
  />
);

export const getFlagXS = (country) => (
  <Image
    attributes={{
      className: 'w-18 h-18 rounded-50% object-cover',
      mediaURL: FLAGS[country],
      nonResponsive: true,
    }}
  />
);

export const getCachedGlobals = (type, globals) => {
  const {
    footer, head, header,
  } = setGlobalOptions({
    data: { globals },
    slug: '',
    type,
  });

  return {
    ...globals,
    footer,
    head: {
      ...head,
      mode: { isDevelopment: checkIsDevelopment() },
    },
    header,
  };
};
