import { useEffect } from 'react';

import Cookies from 'universal-cookie';

import { getUrlParam } from '../components/helpers';
import { get30Days, getXHoursFromNow } from '../helpers/date-helpers';

export function useCookieInit(): void {
  useEffect(() => {
    const cookies = new Cookies();

    const urlCookies = [
      'utm_campaign',
      'utm_content',
      'utm_medium',
      'utm_source',
      'utm_term',
      'utm_group',
      'utm_refacc',
      'utm_program',
      'aclid',
      'gclid',
      'fbclid',
      'msclkid',
      'Product_Interest',
    ];

    const { hostname } = window.location;

    for (const item of urlCookies) {
      const itemValue = getUrlParam(item);
      if (itemValue) {
        cookies.set(item, itemValue, {
          domain: hostname,
          expires: get30Days(),
          path: '/',
        });
      }
    }

    const isExistingCustomer = cookies.get('ExistingCustomer');
    const firstVisitTimeStampCookie = cookies.get('first_visit_timestamp');

    if (!firstVisitTimeStampCookie && !isExistingCustomer) {
      cookies.set('first_visit_timestamp', Date.now(), {
        domain: hostname,
        expires: getXHoursFromNow(1),
        path: '/',
      });
    }
  }, []);
}
