import React, { useEffect } from 'react';

import Cookies from 'universal-cookie';

import { useCookieInit } from '../hooks/useCookieInit';
import Head from './Head';
import Footer from './partials/Footer';
import Header from './partials/Header';
import PreviewModal from './partials/PreviewModal';

export default function Layout({
  alternativePages,
  children,
  footer,
  head,
  header,
  meta,
  mode,
  previewData,
}) {
  const { isDevelopment } = mode;

  useCookieInit();

  useEffect(() => {
    if (meta?.page?.useDesignSystem2?.[0] === false) {
      document.querySelector('body').classList.add('-ds2');
    } else {
      document.querySelector('body').classList.remove('-ds2');
    }
  }, []);

  const cookies = new Cookies();
  const activeExperiments = cookies.get('rippling_experiments') || [];

  const isHidden = false;
  const experimentNames =
    activeExperiments &&
      activeExperiments.map((experiment) => experiment.name).join(' ') ||
    '';

  const { schema } = meta?.seo || {};

  return (
    <div className={`container ${isHidden ? 'hidden' : ''} ${experimentNames}`}>
      <Head alternativePages={alternativePages} head={head} />

      {!!schema && (
        <script
          dangerouslySetInnerHTML={{ __html: schema }}
          type="application/ld+json"
        />
      )}

      <Header
        {...header}
        isMiniItSite={mode.isMiniItSite}
        isMiniSite={mode.isMiniSite}
        locale={mode.locale}
        pageLocale={mode.pageLocale}
        pageLocaleDefaulted={mode.pageLocaleDefaulted}
        publishedLocales={mode.publishedLocales}
      />

      <main>{children}</main>

      {isDevelopment && <PreviewModal previewData={previewData} />}

      <Footer {...footer} />
    </div>
  );
}
